export const environment = {
	production: false,
	keyCloakUrl: "https://protecta.devmente.io:8443/auth",
	soatBaseApiUrl: "protecta.devmente.io:8443/soat",
	sctrBaseApiUrl: "protecta.devmente.io:8443/kuntur",
	integrationApiUrl: "protecta.devmente.io:8443",
	api: {
		quotation: 'https://api.lwlt.stg.protectasecuritycloud.pe',
		stagingProxy: 'https://api.lwlt.stg.protectasecuritycloud.pe/integration',
		lwlt_quotator: 'https://api.lwlt.stg.protectasecuritycloud.pe',
		protectaApiIntegration: 'https://api.lwlt.stg.protectasecuritycloud.pe/integration',
		lwlt_quotator_delete: 'https://dmxbz5025f.execute-api.us-east-1.amazonaws.com/stg/'
	},
	paramsConfig: {
		branch: {
			code: 73,
			description: 'Vida'
		},
		product: {
			code: 1,
			description: 'Vida Ley'
		},
		module: {
			/**
			 * Info: Agregar por cada componente el modulo al que pertenece
			 */
			riskCoverageRate: {
				code: 7,
				description: 'Plan Maestro'
			},
			WeightComplementaryCoverage: {
				code: 22,
				description: "Plan Maestro"
			},
			hannoverTable: {
				code: 666,
				description: 'Tabla de Mortalidad'
			},
			complementaryCoverages: {
				law: [7, 11, 15],
				basic: 8,
				full: 9,
				special: 10,
				master: 22
			}
		}
	},
	moduleConfig: {
		params: 'Cotizador'
	},
	aws: {
		aws_project_region: 'us-east-1',
		aws_cognito_identity_pool_id: 'us-east-1:969141358364',
		aws_cognito_region: 'us-east-1',
		aws_user_pools_id: 'us-east-1_AvSSyTCzf',
		aws_user_pools_web_client_id: '240cr2054g6p6pm1u3dsbr1uf4',
		aws_appsync_graphqlEndpoint: "https://z3yahu2fvrhatnmvczlz5e74my.appsync-api.us-east-1.amazonaws.com/graphql",
		aws_appsync_graphqlCoreEndpoint: "https://api-coreintegration.tcdg.stg.protectasecuritycloud.pe/graphql",
		aws_appsync_graphqlVL: "https://m6mpj7ma5baenoq7x5iconkmmq.appsync-api.us-east-1.amazonaws.com/graphql",
		cookieStorage: {
			domain: '.stg.protectasecuritycloud.pe',
			path: '/',
			secure: false
		},
		oauth: {}
	},
	aws_authentication: {
		quotation_graphql_authentication_value: "da2-itcmqbufcfeujlgf4irvcikpc4",
		core_graphql_authentication_value: 'da2-kwcvphgyrna6pchye3sdf2qifq',
		vl_graphql_authentication_value: 'da2-hfoff6qgd5g5pnle7zpi6rj5yq'
	},
	login: 'https://login.core.stg.protectasecuritycloud.pe/#/auth/sign-in',
	versioncheckurl: "https://cotizador.lwlt.stg.protectasecuritycloud.pe/version.json"
};
